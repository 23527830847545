var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionSankeyChartWidget', {
    attrs: {
      "selectedTimeframeParam": _vm.selectedTimeframeParam,
      "assets": this.assetStore.assetsSelected(),
      "selectedUIUnitSystem": _vm.selectedUIUnitSystem,
      "isMobile": _vm.isMobile,
      "elementSize": _vm.elementSize
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }