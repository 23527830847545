<template>
  <TableWidgetStyled>
    <TableV2
      :title="$t('sankeyChartTable.title')"
      :headers="headers"
      :rows="rows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="isLoading"
      :isMobile="isMobile"
      :enableFooter="true"
      :enableSearch="false"
      :messages="messages"
      :disableTitleBar="true"
      :totals="totalRows"
      @tableExport="tableExport"
    />
  </TableWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { compact, partition, concat, orderBy } from 'lodash'
import units, { resolveUnitUI } from '@/utils/units'
import { exportTableDataToCSV } from '@/utils/export'
import parameterize from '@/utils/parameterize'

import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import { TABLE_SORT_TYPES } from '@/constants/settings'

export const TableWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.navFontNormal};
  overflow: hidden;
`
export default {
  props: {
    massFlow: {
      type: Array,
      required: true,
    },
    selectedUIUnitSystem: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchQuery: {
      type: String,
      required: false,
    },
  },
  components: {
    TableV2,
    TableWidgetStyled,
  },
  data() {
    return {
      sorting: {
        key: 'sourceGeofence',
        asc: true,
      },
      filterOptionsSelected: [],
      filtersAvailable: [],
    }
  },
  computed: {
    headers() {
      const headers = [
        {
          id: `header_source`,
          label: this.$t('sankeyChartTable.sourceGeofence'),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'sourceGeofence',
          size: 'medium',
        },
        {
          id: `header_target`,
          label: this.$t('sankeyChartTable.targetGeofence'),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'targetGeofence',
          size: 'medium',
        },
        {
          id: `header_tonnage`,
          label: this.$t('sankeyChartTable.tonnage'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'tonnage',
          size: 'medium',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'mass').replace('mt', 't'),
        },
        {
          id: `header_sum_distance`,
          label: this.$t('sankeyChartTable.sumDistance'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'sumDistance',
          size: 'medium',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'length'),
        },
        {
          id: `header_avg_distance`,
          label: this.$t('sankeyChartTable.avgDistance'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'avgDistance',
          size: 'medium',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'length'),
        },
        {
          id: `header_sum_duration`,
          label: this.$t('sankeyChartTable.sumDuration'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'sumDuration',
          size: 'medium',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'time'),
        },
        {
          id: `header_avg_duration`,
          label: this.$t('sankeyChartTable.avgDuration'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'avgDuration',
          size: 'medium',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'time'),
        },
        {
          id: `header_tonnage_kilometer`,
          label: this.$t('sankeyChartTable.tonnageKilometer'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'tonnageKilometer',
          size: 'large',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'massVelocity'),
        },
        {
          id: `header_fuel_per_tonnage`,
          label: this.$t('sankeyChartTable.fuelPerTonnage'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'fuelPerTonnage',
          size: 'large',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'volumeMass'),
        },
        {
          id: `header_fuel_per_hour`,
          label: this.$t('sankeyChartTable.fuelPerHour'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'fuelPerHour',
          size: 'large',
          unit: resolveUnitUI(this.selectedUIUnitSystem, 'volumeFlowRates'),
        },
        {
          id: `header_no_cycles`,
          label: this.$t('sankeyChartTable.noCycles'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'noCycles',
          size: 'medium',
        },
        {
          id: `header_material_type`,
          label: this.$t('sankeyChartTable.materialType'),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'materialType',
          size: 'medium',
        },
      ]
      return headers
    },
    groupedMassflowData() {
      return this.massFlow.reduce((acc, item) => {
        const found = acc.find(f => f.sourceGeofence === item.sourceGeofence && f.targetGeofence === item.targetGeofence)
        if (found) {
          found.noCycles += item.noCycles
          found.tonnage += item.tonnage
          found.sumDistance += item.sumDistance
          found.avgDistance += item.avgDistance
          found.sumDuration += item.sumDuration
          found.avgDuration += item.avgDuration
          found.tonnageKilometer += item.tonnageKilometer
          found.fuelPerTonnage += item.fuelPerTonnage
          found.fuelPerHour += item.fuelPerHour
        } else {
          acc.push({
            sourceGeofence: item.sourceGeofence,
            targetGeofence: item.targetGeofence,
            tonnage: item.tonnage,
            noCycles: item.noCycles,
            sumDistance: item.sumDistance,
            avgDistance: item.avgDistance,
            sumDuration: item.sumDuration,
            avgDuration: item.avgDuration,
            tonnageKilometer: item.tonnageKilometer,
            fuelPerTonnage: item.fuelPerTonnage,
            fuelPerHour: item.fuelPerHour,
            materialType: item.materialType,
          })
        }
        return acc
      }, [])
    },
    massFlowSorted() {
      const partitions = partition(this.groupedMassflowData, x => !!(x?.[this.sorting?.key] ?? null))
      const sortDirection = this.sorting.asc ? 'asc' : 'desc'
      return concat(orderBy(partitions[0], [this.sorting.key], [sortDirection]), partitions[1])
    },
    rows() {
      const rows = this.massFlowSorted.map((item, rowIndex) => {
        let cells = [
          {
            id: `${item.asset}_sourceGeofence`,
            sortableValue: item.sourceGeofence !== null ? String(item.sourceGeofence) : '-',
            mobilePrimarycolLabel: String(item.sourceGeofence),
            headerId: 'header_source',
          },
          {
            id: `${item.asset}_targetGeofence`,
            sortableValue: item.targetGeofence !== null ? String(item.targetGeofence) : '-',
            isMobilePrimarycol: true,
            headerId: `header_target`,
          },
          {
            id: `${item.asset}_tonnage`,
            sortableValue:
              item.tonnage !== null
                ? units(
                    item.tonnage,
                    'kg',
                    resolveUnitUI(this.selectedUIUnitSystem, 'mass'),
                    0,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_tonnage`,
          },
          {
            id: `${item.asset}_sum_distance`,
            sortableValue:
              item.sumDistance !== null
                ? units(
                    item.sumDistance,
                    'm',
                    resolveUnitUI(this.selectedUIUnitSystem, 'length'),
                    2,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_sum_distance`,
          },
          {
            id: `${item.asset}_avg_distance`,
            sortableValue:
              item.avgDistance !== null
                ? units(
                    item.avgDistance,
                    'm',
                    resolveUnitUI(this.selectedUIUnitSystem, 'length'),
                    2,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_avg_distance`,
          },
          {
            id: `${item.asset}_sum_duration`,
            sortableValue:
              item.sumDuration !== null
                ? units(
                    item.sumDuration,
                    's',
                    resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                    2,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_sum_duration`,
          },
          {
            id: `${item.asset}_avg_duration`,
            sortableValue:
              item.avgDuration !== null
                ? units(
                    item.avgDuration,
                    's',
                    resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                    2,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_avg_duration`,
          },
          {
            id: `${item.asset}_tonnage_kilometer`,
            sortableValue:
              item.tonnageKilometer !== null
                ? units(
                    item.tonnageKilometer,
                    's',
                    resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                    2,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_tonnage_kilometer`,
          },
          {
            id: `${item.asset}_fuel_per_tonnage`,
            sortableValue:
              item.fuelPerTonnage !== null
                ? units(
                    item.fuelPerTonnage,
                    'l/kg',
                    resolveUnitUI(this.selectedUIUnitSystem, 'volumeMass'),
                    2,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_fuel_per_tonnage`,
          },
          {
            id: `${item.asset}_fuel_per_hour`,
            sortableValue:
              item.fuelPerHour !== null
                ? units(
                    item.fuelPerHour,
                    'l/s',
                    resolveUnitUI(this.selectedUIUnitSystem, 'volumeFlowRates'),
                    2,
                    false,
                    true,
                    false,
                    this.thousandsSeperator,
                    this.decimalSeperator,
                    true,
                  )
                : '-',
            headerId: `header_fuel_per_hour`,
          },
          {
            id: `${item.asset}_no_cycles`,
            sortableValue: item?.noCycles !== null ? item?.noCycles : '-',
            headerId: `header_no_cycles`,
          },
          {
            id: `${item.asset}_material_type`,
            sortableValue: item.materialType === null ? '-' : parameterize(item.materialType),
            headerId: `header_material_type`,
          },
        ]
        return {
          rowId: `row_${rowIndex}`,
          cells,
        }
      })

      const filteredTable = rows.filter(row => {
        const props = compact(row.cells.map(item => item.sortableValue))
        return props.some(
          prop =>
            !this.searchQuery ||
            (typeof prop === 'string'
              ? prop.toLowerCase().includes(this.searchQuery.toLowerCase())
              : prop.toString(10).includes(this.searchQuery)),
        )
      })
      return filteredTable
    },
    totalRows() {
      let cells = [
        {
          id: 'total_source',
          sortableValue: '',
          headerId: 'header_source',
        },
        {
          id: 'total_target',
          sortableValue: this.$tc('total'),
          headerId: `header_target`,
        },
        {
          id: 'total_tonnage',
          sortableValue:
            this.findTotalValue('tonnage') !== null
              ? units(
                  this.findTotalValue('tonnage'),
                  'kg',
                  resolveUnitUI(this.selectedUIUnitSystem, 'mass'),
                  0,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          headerId: `header_tonnage`,
        },
        {
          id: 'total_sum_distance',
          sortableValue:
            this.findTotalValue('sumDistance') !== null
              ? units(
                  this.findTotalValue('sumDistance'),
                  'm',
                  resolveUnitUI(this.selectedUIUnitSystem, 'length'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          headerId: `header_sum_distance`,
        },
        {
          id: 'total_avg_distance',
          sortableValue:
            this.findTotalValue('avgDistance') !== null
              ? units(
                  this.findTotalValue('avgDistance'),
                  'm',
                  resolveUnitUI(this.selectedUIUnitSystem, 'length'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          headerId: `header_avg_distance`,
        },
        {
          id: 'total_sum_duration',
          sortableValue:
            this.findTotalValue('sumDuration') !== null
              ? units(
                  this.findTotalValue('sumDuration'),
                  's',
                  resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          headerId: `header_sum_duration`,
        },
        {
          id: `total_avg_duration`,
          sortableValue:
            this.findTotalValue('avgDuration') !== null
              ? units(
                  this.findTotalValue('avgDuration'),
                  's',
                  resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          headerId: `header_avg_duration`,
        },
        {
          id: `total_tonnage_kilometer`,
          sortableValue:
            this.findTotalValue('tonnageKilometer') !== null
              ? units(
                  this.findTotalValue('tonnageKilometer'),
                  's',
                  resolveUnitUI(this.selectedUIUnitSystem, 'time'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          headerId: `header_tonnage_kilometer`,
        },
        {
          id: `total_fuel_per_tonnage`,
          sortableValue:
            this.findTotalValue('fuelPerTonnage') !== null
              ? units(
                  this.findTotalValue('fuelPerTonnage'),
                  'l/kg',
                  resolveUnitUI(this.selectedUIUnitSystem, 'volumeMass'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          headerId: `header_fuel_per_tonnage`,
        },
        {
          id: `total_fuel_per_hour`,
          sortableValue:
            this.findTotalValue('fuelPerHour') !== null
              ? units(
                  this.findTotalValue('fuelPerHour'),
                  'l/s',
                  resolveUnitUI(this.selectedUIUnitSystem, 'volumeFlowRates'),
                  2,
                  false,
                  true,
                  false,
                  this.thousandsSeperator,
                  this.decimalSeperator,
                  true,
                )
              : '-',
          headerId: `header_fuel_per_hour`,
        },
        {
          id: `total_no_cycles`,
          sortableValue: this.findTotalValue('noCycles') !== null ? this.findTotalValue('noCycles') : '-',
          headerId: `header_no_cycles`,
        },
        {
          id: `total_material_type`,
          sortableValue: '',
          headerId: `header_material_type`,
        },
      ]
      return [
        {
          rowId: `total`,
          cells,
        },
      ]
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        const dimensionName = this.$t('massflow')

        message =
          this.searchQuery === ''
            ? this.$t('messages.noDimensionData', { dimension: dimensionName })
            : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
  },
  methods: {
    tableExport(type) {
      if (type === 'csv') {
        const mappedHeaders = this.headers.map(header => ({ data: header.label }))
        const mappedRows = this.rows.flatMap(header => ({ data: header.cells })).flatMap(item => item.data)
        exportTableDataToCSV(mappedHeaders, mappedRows, 'talpa-export_sankey_table')
      }
    },
    findTotalValue(element) {
      return this.massFlowSorted.reduce((accumulator, object) => {
        return accumulator + object[element]
      }, 0)
    },
  },
}
</script>
