<template>
  <AssetDimensionSankeyChartWidget
    :selectedTimeframeParam="selectedTimeframeParam"
    :assets="this.assetStore.assetsSelected()"
    :selectedUIUnitSystem="selectedUIUnitSystem"
    :isMobile="isMobile"
    :elementSize="elementSize"
  />
</template>

<script>
import AssetDimensionSankeyChartWidget from '@/components/Atomic/Organisms/Dashboard/AssetDimensionSankeyChartWidget'
import localesMixin from '@/mixins/locales'
import { TimeframeMixin } from '@common/mixins'
import resizeMixin from '@/mixins/resize'
import { get } from 'lodash'
import { useAssetStore } from '@/stores/assets'

export default {
  inject: ['theme', 'uiSettings'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  props: {
    widget: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
    },
    pdfData: {
      type: Object,
      required: false,
    },
  },
  mixins: [TimeframeMixin, localesMixin, resizeMixin],
  components: {
    AssetDimensionSankeyChartWidget,
  },
  data() {
    return {
      isMobile: true,
      elementSize: 96,
    }
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        this.elementSize = size
        const isMobile = size < 600 && size >= 96
        this.isMobile = isMobile || (this.isPreview && this.isMobilePreview)
      })
    },
  },
}
</script>
