<template>
  <FilterWrapperStyled :elementSize="elementSize">
    <span> {{ $tc('feed.filter.title') }}:</span>
    <CircleBadge :label="filterOptionsSelected.length" size="S" />
    <div class="filters">
      <CustomMultiSelect
        v-for="filter in filtersAvailable"
        :key="filter.id"
        track-by="id"
        openDirection="bottom"
        :class="filter.class"
        :label="filter.label"
        :value="filter.value"
        :options="filter.options"
        :shouldNotResetSelectedOption="true"
        :disabled="filter.options.length === 0"
        :closeOnSelect="!filter.isMultiple"
        :searchable="filter.searchable"
        :multiple="filter.isMultiple"
        :placeholder="filter.placeholder"
        :modelName="filter.modelName"
        :maxHeight="256"
        :optionsLimit="6"
        :applyFilter="false"
        :internalSearch="true"
        :customTagSlot="true"
        :customNoOptionsSlot="true"
        @select="$emit('setSelectedFilterOption', $event)"
        @remove="$emit('setSelectedFilterOption', $event)"
      >
        <template v-slot:customLabelIcon>
          <ShowAsLabel>{{ $tc(filter.modelName, 1) }}:</ShowAsLabel>
        </template>
        <template v-slot:tag="option">
          <SelectedTagStyled>
            <strong v-if="filter.value.length === 1">
              {{ filter.value[0].label }}
            </strong>
            <strong v-else-if="filter.value[0].id === option.option.id">{{ filter.placeholder }} {{ filter.value.length }}</strong>
            <span v-else />
          </SelectedTagStyled>
        </template>
        <template v-slot:noOptions>
          <strong>{{ filter.placeholder }}</strong>
        </template>
      </CustomMultiSelect>
    </div>
    <FilterActionStyled>
      <button class="styleless" :disabled="filterOptionsSelected.length === 0" @click="$emit('resetFilters')">{{ $tc('reset') }}</button>
    </FilterActionStyled>
  </FilterWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { buttonReset, flexCenter } from '@styles/mixins'
import chroma from 'chroma-js'
import { CustomMultiSelect } from '@common/components'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'

const FilterWrapperStyled = styled('div')`
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 0.5rem;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  display: grid;
  grid-template-columns: min-content min-content 1fr min-content;
  grid-gap: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  .filters {
    display: grid;
    grid-template-rows: 1fr;
    ${({ elementSize }) => elementSize < 889 && `grid-template-rows: 1fr 1fr`};

    ${({ elementSize }) => elementSize > 645 && ` grid-auto-flow: column;  grid-auto-columns: min-content;`};
    grid-gap: 1rem;
    .filter {
      .multiselect {
        min-height: 0;
        margin: 0;
        color: ${({ theme }) => theme.colors.atomic.textMain};
        .multiselect__tags {
          min-width: 15rem !important;
          display: flex;
          align-items: center;
          background: ${({ theme }) => theme.colors.atomic.bunkerLight};
          &:hover {
            background: ${({ theme }) => theme.colors.atomic.tableColumnHeaderBG};
          }
          border-radius: 3rem;
          padding: 0.1rem 2.25rem 0.1rem 1rem;
          min-width: 13rem;
          min-height: 0;
          height: 2.5rem;
          .multiselect__single {
            top: 0rem;
            font-size: 14px;
            font-weight: bold;
            padding: 0;
            margin: 0;
          }
          .multiselect__element {
            background: ${({ theme }) => theme.colors.atomic.mirage};
          }
          .multiselect__placeholder {
            padding: 0;
            margin: 0;
            color: ${({ theme }) => theme.colors.atomic.textMain};
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }
`
const FilterActionStyled = styled('div')`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.atomic.toolsMenuBG};
  ${({ isMobile }) => isMobile && 'margin-left: 0; margin-bottom: 0.5rem; position:absolute; bottom:3.5rem;'};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 3px 6px ${({ theme }) => chroma(theme.colors.black).alpha(0.32).css()};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? chroma(theme.colors.white).alpha(0.16).css() : chroma(theme.colors.black).alpha(0.16).css())};
  padding: 0.3rem;
  button.styleless {
    ${buttonReset}
    ${flexCenter}
    color: ${({ theme }) => theme.colors.atomic.primary};
  }
`
const SelectedTagStyled = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.primary};
`

export default {
  props: {
    elementSize: {
      type: Number,
      required: false,
      default: 96,
    },
    filterOptionsSelected: {
      type: Array,
      required: true,
    },
    filtersAvailable: {
      type: Array,
      required: true,
    },
  },
  components: {
    CustomMultiSelect,
    FilterWrapperStyled,
    FilterActionStyled,
    SelectedTagStyled,
    CircleBadge,
  },
}
</script>
