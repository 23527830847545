<template>
  <SankeyChartWidgetStyled>
    <NoDataBlockStyled v-if="!$apollo.loading && massFlowData.length === 0">
      {{ $tc('messages.noDataForSelectedTime') }}
    </NoDataBlockStyled>
    <template v-else-if="massFlowData.length > 0 && sourcesSummed.length > 0">
      <svg class="sankey" :viewBox="`0 0 ${totalWidth} ${totalHeight}`">
        <defs>
          <LinearGradientStyled
            v-for="materialType in materialTypes"
            :key="materialType.id"
            :id="materialType.id"
            :endColor="materialType.endColor"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" id="start" />
            <stop offset="100%" id="end" />
          </LinearGradientStyled>
        </defs>
        <g class="sources">
          <SourceStyled v-for="(source, i) in sourcesSummed" :key="i" :x="0" :y="source.y" :width="sourceWidth" :height="source.size" />
        </g>
        <g class="targets">
          <TargetStyled
            v-for="(target, i) in targetsSummed"
            :key="i"
            :type="target.type"
            :targetColor="target.targetColor"
            :x="totalWidth - sourceWidth"
            :y="target.y"
            :width="sourceWidth"
            :height="target.size"
          />
        </g>
        <g class="flows" v-if="true">
          <path class="flow" v-for="(flow, i) in flowsWithCoords" :key="`flow-${i}`" :fill="`url(#grad_${flow.type})`" :d="flow.d" />
        </g>
        <g class="names">
          <SvgTextStyled
            v-for="(source, i) in sourcesSummed"
            :key="'text-source-' + i"
            :x="sourceWidth * 1.5"
            :y="source.y + Math.round(source.size / 2)"
            font-size="32px;"
            alignment-baseline="middle"
          >
            {{ source.name }} &middot; {{ source.massFormated }} &middot; {{ source.cycles }}
            {{ $tc('sankeyChart.cycle', source.cycles) }}
          </SvgTextStyled>
          <SvgTextStyled
            v-for="(target, i) in targetsSummed"
            :key="'text-target-' + i"
            :x="totalWidth - sourceWidth * 1.5"
            :y="target.y + Math.round(target.size / 2)"
            font-size="32px;"
            alignment-baseline="middle"
            text-anchor="end"
          >
            {{ target.name }} &middot; {{ target.massFormated }} &middot; {{ target.cycles }}
            {{ $tc('sankeyChart.cycle', target.cycles) }}
          </SvgTextStyled>
        </g>
      </svg>
    </template>
  </SankeyChartWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import units, { resolveUnitUI } from '@/utils/units'
import parameterize from '@/utils/parameterize'
import { flexCenter } from '@styles/mixins'
import debounce from 'lodash/debounce'
import chroma from 'chroma-js'
import flow from 'lodash/fp/flow'
import groupBy from 'lodash/fp/groupBy'
import reduce from 'lodash/fp/reduce'
import sumBy from 'lodash/fp/sumBy'
import Vue from 'vue'
import resizeMixin from '@/mixins/resize'
import { compact } from 'lodash'

const SankeyChartWidgetStyled = styled('div')`
  position: relative;
  width: 100%;
  height: auto;
  overflow-y: auto;
  > .sankey {
    margin-top: -7px;
  }
`
const NoDataBlockStyled = styled('div')`
  ${flexCenter}
  height: 100%;
  width: 100%;
`
const SourceStyled = styled('rect')`
  fill: ${p => p.theme.colors.charts.first};
`

const TargetStyled = styled('rect')`
  fill: ${p => p.targetColor};
`
const SvgTextStyled = styled('text')`
  fill: ${p => p.theme.colors.white};
  font-size: 2rem;
`

const LinearGradientStyled = styled('linearGradient')`
  #start {
    stop-color: ${p => chroma(p.theme.colors.charts.first).alpha(0.5).css()};
  }
  #end {
    stop-color: ${p => p.endColor};
  }
`

export default {
  inject: ['theme'],
  mixins: [resizeMixin],
  props: {
    massFlow: {
      type: Array,
      required: true,
    },
    selectedUIUnitSystem: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    searchQuery: {
      type: String,
      required: false,
    },
  },
  components: {
    SankeyChartWidgetStyled,
    NoDataBlockStyled,
    SvgTextStyled,
    SourceStyled,
    TargetStyled,
    LinearGradientStyled,
  },
  data() {
    return {
      spacing: 10,
      sourceWidth: 20,
      ratio: 0,
      totalWidth: 1800,
      width: 0,
      height: 0,
      minHeight: 40,
      useLog: true,
    }
  },
  computed: {
    flowColorsAvail() {
      return [
        chroma(this.theme.colors.charts.second).alpha(0.5).css(),
        chroma(this.theme.colors.charts.third).alpha(0.5).css(),
        chroma(this.theme.colors.mediumGrey).alpha(0.5).css(),
        chroma(this.theme.colors.redDarker).alpha(0.5).css(),
        chroma(this.theme.colors.primary).alpha(0.5).css(),
        chroma(this.theme.colors.azul).alpha(0.5).css(),
      ]
    },
    materialTypes() {
      const materialTypes = this.massFlowData.reduce((acc, flow) => {
        acc[parameterize(flow.materialType)] = true
        return acc
      }, {})
      return Object.keys(materialTypes).map((key, i) => {
        const endColor = i >= this.flowColorsAvail.length ? chroma(this.theme.colors.muted).alpha(0.5).css() : this.flowColorsAvail[i]
        return {
          id: `grad_${key}`,
          endColor,
        }
      })
    },
    massFlowData() {
      const massFlow = this.massFlow.reduce((acc, item) => {
        const found = acc.find(f => f.sourceGeofence === item.sourceGeofence && f.targetGeofence === item.targetGeofence)
        if (found) {
          found.noCycles += item.noCycles
          found.tonnage += item.tonnage
        } else {
          acc.push({
            sourceGeofence: item.sourceGeofence,
            targetGeofence: item.targetGeofence,
            tonnage: item.tonnage,
            noCycles: item.noCycles,
            massFormated: units(
              item.tonnage,
              'kg',
              resolveUnitUI(this.selectedUIUnitSystem, 'mass'),
              0,
              false,
              true,
              false,
              this.thousandsSeperator,
              this.decimalSeperator,
              true,
            ),
            materialType: parameterize(item.materialType),
          })
        }
        return acc
      }, [])

      return massFlow.filter(row => {
        const props = compact(Object.values(row))
        return props.some(val => val?.toString().toLowerCase().includes(this.searchQuery.toString().toLowerCase()))
      })
    },
    flowsWithCoordsSorted() {
      return []
    },
    flowsMapped() {
      if (this.massFlowData.length < 1) {
        return []
      }
      const flows = this.massFlowData.filter(flow => flow.tonnage > 0)
      let minMass = flows[0]
      let maxMass = flows[0]
      flows.forEach(flow => {
        if (minMass.tonnage > flow.tonnage) {
          minMass = flow
        }
        if (maxMass.tonnage < flow.tonnage) {
          maxMass = flow
        }
      })
      const mappedFlows = flows.map(flow => {
        return {
          ...flow,
          massPerCycle: flow.tonnage / flow.noCycles,
          heightScale: this.useLog ? Math.log(flow.tonnage / minMass.tonnage) + 1 : flow.tonnage / minMass.tonnage,
        }
      })
      return mappedFlows
    },
    sourcesSummed() {
      return this.sumSourcesOrTargets(this.flowsMapped, this.minHeight, this.spacing, true)
    },
    targetsSummed() {
      return this.sumSourcesOrTargets(this.flowsMapped, this.minHeight, this.spacing, false)
    },
    totalHeight() {
      if (this.sourcesSummed.length < 1) {
        return 0
      }
      if (this.sourcesSummed.length > this.targetsSummed.length) {
        return sumBy('size', this.sourcesSummed) + this.sourcesSummed.length * this.spacing || 0
      }
      return sumBy('size', this.targetsSummed) + this.targetsSummed.length * this.spacing || 0
    },
    flowsWithCoords() {
      if (!this.sourcesSummed || this.sourcesSummed.length < 1) {
        return []
      }
      const flows = this.flowsMapped
      const sourcesSummed = this.sourcesSummed
      const targetsSummed = this.targetsSummed

      const startX = this.sourceWidth
      const endX = this.totalWidth - this.sourceWidth
      const diffX = endX - startX
      return flows.map(f => {
        const source = sourcesSummed.find(s => s.name === f.sourceGeofence)
        const target = targetsSummed.find(s => s.name === f.targetGeofence)
        if (source && target) {
          // const relTonnage = f.tonnage / tonnageSummed
          const size = f.heightScale * this.minHeight
          let sourceInnerOffsetY = 0
          source.flows.some(sf => {
            if (sf === f) {
              return true
            }
            sourceInnerOffsetY += sf.heightScale * this.minHeight
          })
          let targetInnerOffsetY = 0
          target.flows.some(tf => {
            if (tf === f) {
              return true
            }
            targetInnerOffsetY += tf.heightScale * this.minHeight
          })
          const d = `
            M ${startX},${source.y + sourceInnerOffsetY}
            C ${startX + diffX / 2},${source.y + sourceInnerOffsetY} ${endX - diffX / 2},${target.y + targetInnerOffsetY} ${endX},${
            target.y + targetInnerOffsetY
          }
            L ${endX},${target.y + targetInnerOffsetY + size}
            C ${endX - diffX / 2},${target.y + targetInnerOffsetY + size} ${startX + diffX / 2},${
            source.y + sourceInnerOffsetY + size
          } ${startX},${source.y + sourceInnerOffsetY + size}
            L ${startX},${source.y + sourceInnerOffsetY}
          `

          const obj = {
            tonnage: f.mass,
            size,
            d,
            score: target.y - source.y,
            type: f.materialType,
          }
          Vue.set(obj, 'isActive', false)
          return obj
        } else {
          throw new Error('missing source or target')
        }
      })
    },
  },
  methods: {
    handleResize: debounce(function () {
      if (this.$el) {
        this.width = this.$el.clientWidth
        this.height = this.$el.clientHeight
        this.ratio = this.width / this.height
      }
    }, 100),
    sumSourcesOrTargets(flows, minHeight, spacing, isSources = true) {
      if (!flows || flows.length < 1) {
        return []
      }
      const grouped = flow(
        groupBy(isSources ? 'sourceGeofence' : 'targetGeofence'),
        reduce((acc, o) => {
          const sum = sumBy('tonnage', o)
          const sumCycles = sumBy('noCycles', o)
          const sumHeightScale = sumBy('heightScale', o)
          const size = sumHeightScale * minHeight
          const prev = acc[acc.length - 1]
          const y = prev ? prev.y + prev.size + spacing : spacing
          const name = isSources ? o[0].sourceGeofence : o[0].targetGeofence
          let type = o[0].materialType
          const mt = this.materialTypes.find(mt => mt.id === `grad_${parameterize(type)}`)
          const targetColor = mt ? mt.endColor : null
          acc.push({
            name,
            mass: sum,
            massFormated: units(
              sum,
              'kg',
              resolveUnitUI(this.selectedUIUnitSystem, 'mass'),
              0,
              true,
              true,
              false,
              this.thousandsSeperator,
              this.decimalSeperator,
              true,
            ),
            cycles: sumCycles,
            size,
            y: y,
            flows: o,
            type,
            targetColor,
          })
          return acc
        }, []),
      )(flows)
      return grouped
    },
  },
}
</script>
