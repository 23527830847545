var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionSankeyChartWidgetStyled', {
    attrs: {
      "elementSize": _vm.elementSize
    }
  }, [_c('Actions', [_c('span', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('CircleBadge', {
    staticClass: "filterButton",
    attrs: {
      "label": this.massFlowFiltered.length,
      "size": "M"
    }
  })], 1), _c('div', {
    staticClass: "search"
  }, [_c('SearchBar', {
    attrs: {
      "searchQuery": _vm.searchQuery,
      "placeHolder": 'actions.search',
      "isLoading": _vm.isLoading
    },
    on: {
      "filterShareList": function filterShareList($event) {
        return _vm.textSearch($event);
      }
    }
  })], 1), _c('Row', [_vm.viewMode === 'table' ? _c('ButtonStyled', {
    attrs: {
      "active": _vm.viewMode === 'table'
    },
    on: {
      "click": function click($event) {
        return _vm.selectViewMode('sankey');
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('SankeyChartIcon', {
    class: {
      active: _vm.viewMode === 'table'
    }
  })], 1)], 1) : _vm._e(), _vm.viewMode === 'sankey' ? _c('ButtonStyled', {
    attrs: {
      "active": _vm.viewMode === 'sankey'
    },
    on: {
      "click": function click($event) {
        return _vm.selectViewMode('table');
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('TableIcon', {
    class: {
      active: _vm.viewMode === 'sankey'
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('FilterPanel', {
    attrs: {
      "elementSize": _vm.elementSize,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "filtersAvailable": _vm.filtersAvailable
    },
    on: {
      "setSelectedFilterOption": _vm.setSelectedFilterOption,
      "resetFilters": _vm.resetFilters
    }
  }), _c('ContentWrapperStyled', {
    attrs: {
      "hasNoData": _vm.hasNoData
    }
  }, [_vm.$apollo.loading || this.assetIds.length === 0 ? _c('TalpaLoaderWrapper') : _vm.hasNoData ? _c('NoDataStyled', [_vm._v(_vm._s(_vm.message))]) : [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.viewMode === 'table' ? _c('SankeyChartTable', {
    attrs: {
      "isLoading": _vm.$apollo.loading,
      "massFlow": _vm.massFlowFiltered,
      "selectedUIUnitSystem": _vm.selectedUIUnitSystem,
      "isMobile": _vm.isMobile,
      "searchQuery": _vm.searchQuery.toString()
    }
  }) : _vm._e()], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.viewMode === 'sankey' ? _c('SankeyChartWidget', {
    attrs: {
      "massFlow": _vm.massFlowFiltered,
      "selectedUIUnitSystem": _vm.selectedUIUnitSystem,
      "isLoading": _vm.$apollo.loading,
      "searchQuery": _vm.searchQuery.toString()
    }
  }) : _vm._e()], 1)]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }