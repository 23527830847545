<template>
  <AssetDimensionSankeyChartWidgetStyled :elementSize="elementSize">
    <Actions>
      <span class="title">
        {{ title }}
        <CircleBadge class="filterButton" :label="this.massFlowFiltered.length" size="M" />
      </span>
      <div class="search">
        <SearchBar
          :searchQuery="searchQuery"
          :placeHolder="'actions.search'"
          :isLoading="isLoading"
          @filterShareList="textSearch($event)"
        />
      </div>
      <Row>
        <ButtonStyled :active="viewMode === 'table'" @click="selectViewMode('sankey')" v-if="viewMode === 'table'">
          <transition name="fade">
            <SankeyChartIcon :class="{ active: viewMode === 'table' }" />
          </transition>
        </ButtonStyled>
        <ButtonStyled :active="viewMode === 'sankey'" @click="selectViewMode('table')" v-if="viewMode === 'sankey'">
          <transition name="fade">
            <TableIcon :class="{ active: viewMode === 'sankey' }" />
          </transition>
        </ButtonStyled>
      </Row>
    </Actions>
    <FilterPanel
      :elementSize="elementSize"
      :filterOptionsSelected="filterOptionsSelected"
      :filtersAvailable="filtersAvailable"
      @setSelectedFilterOption="setSelectedFilterOption"
      @resetFilters="resetFilters"
    />
    <ContentWrapperStyled :hasNoData="hasNoData">
      <TalpaLoaderWrapper v-if="$apollo.loading || this.assetIds.length === 0" />
      <NoDataStyled v-else-if="hasNoData">{{ message }}</NoDataStyled>
      <template v-else>
        <transition name="fade">
          <SankeyChartTable
            v-if="viewMode === 'table'"
            :isLoading="$apollo.loading"
            :massFlow="massFlowFiltered"
            :selectedUIUnitSystem="selectedUIUnitSystem"
            :isMobile="isMobile"
            :searchQuery="searchQuery.toString()"
          />
        </transition>
        <transition name="fade">
          <SankeyChartWidget
            v-if="viewMode === 'sankey'"
            :massFlow="massFlowFiltered"
            :selectedUIUnitSystem="selectedUIUnitSystem"
            :isLoading="$apollo.loading"
            :searchQuery="searchQuery.toString()"
          />
        </transition>
      </template>
    </ContentWrapperStyled>
  </AssetDimensionSankeyChartWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexStartCenter } from '@styles/mixins'
import { ButtonSolid as ButtonSolidStyled } from '@styles/buttons'
import TableIcon from '@common/components/src/TalpaIcons/WidgetTypes/TableIcon.svg'
import SankeyChartIcon from '@common/components/src/TalpaIcons/WidgetTypes/SankeyChartIcon.svg'
import SankeyChartWidget from '@/components/Atomic/Molecules/SankeyChartWidget'
import SankeyChartTable from '@/components/Atomic/Molecules/SankeyChartTable'
import MASSFLOW_QUERY from '#/graphql/assetDimensions/massFlow.gql'
import { TalpaLoaderWrapper } from '@common/components'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'
import { get, orderBy, uniqBy, groupBy as group } from 'lodash'
import FilterPanel from '@/components/Atomic/Molecules/FilterPanelMolecule'
import SearchBar from '@/components/Atomic/Molecules/SearchBar'

const AssetDimensionSankeyChartWidgetStyled = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 3.5rem 1fr;
  ${({ elementSize }) => elementSize < 889 && `grid-template-rows: 40px 7.5rem 1fr`};
  ${({ elementSize }) => elementSize < 645 && `grid-template-rows: 40px 10.5rem 1fr`};
  .active {
    path {
      fill: orange;
    }
  }
`

const Actions = styled('div')`
  ${flexStartCenter}
  justify-content: flex-end;
  gap: 0.5rem;
  > .title {
    flex-grow: 1;
    display: flex;
    font-weight: bold;
    text-transform: capitalize;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem;
  }
  .active {
    path {
      fill: ${({ theme }) => theme.colors.atomic.primary};
    }
  }
  .search {
    border-radius: 0.2rem;
    align-items: center;
    display: flex;
    > div {
      height: 2.25rem;
    }
  }
`

const Row = styled('div')`
  display: flex;
  margin-right: 5px;

  button {
    width: 40px;
    height: 40px;
  }
`

const ButtonStyled = styled(ButtonSolidStyled)`
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: transparent;
  &:hover {
    background: ${({ theme }) => theme.colors.atomic.toolMenuHover};
    border-radius: 50%;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`

const ContentWrapperStyled = styled('div')`
  overflow: auto;
  display: flex;
  align-content: center;
  ${({ hasNoData }) =>
    hasNoData &&
    `justify-content: center;
    align-items: center;`};
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`
export default {
  props: {
    assets: {
      type: Array,
      required: true,
    },
    selectedTimeframeParam: {
      type: Object,
      required: true,
    },
    selectedUIUnitSystem: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    elementSize: {
      type: Number,
      required: false,
      default: 96,
    },
  },
  components: {
    AssetDimensionSankeyChartWidgetStyled,
    SankeyChartIcon,
    Actions,
    Row,
    ButtonStyled,
    TableIcon,
    ContentWrapperStyled,
    SankeyChartWidget,
    SankeyChartTable,
    TalpaLoaderWrapper,
    CircleBadge,
    SearchBar,
    FilterPanel,
    NoDataStyled,
  },
  data() {
    return {
      options: [
        { label: 'widgetTypes.SANKEY_CHART', value: 'sankey' },
        { label: 'widgetTypes.TABLE', value: 'table' },
      ],
      massFlow: [],
      viewMode: 'sankey',
      isLoading: false,
      filterOptionsSelected: [],
      searchQuery: '',
    }
  },
  computed: {
    title() {
      return this.viewMode === 'table' ? this.$tc('sankeyChartTable.title') : this.$tc('widgetTypes.SANKEY_CHART')
    },
    assetIds() {
      return this.assets.map(({ id }) => id)
    },
    uniqueSourceGeofence() {
      return uniqBy(this.massFlow, 'sourceGeofence')
        .map(massflow => {
          return { name: massflow.sourceGeofence, __typename: 'SourceGeofence' }
        })
        .filter(e => e.name)
    },
    uniqueTargetGeofence() {
      return uniqBy(this.massFlow, 'targetGeofence')
        .map(massflow => {
          return { name: massflow.targetGeofence, __typename: 'TargetGeofence' }
        })
        .filter(e => e.name)
    },
    uniqueMaterialType() {
      return uniqBy(this.massFlow, 'materialType')
        .map(massflow => {
          return { name: massflow.materialType, __typename: 'MaterialType' }
        })
        .filter(e => e.name)
    },
    filtersAvailable() {
      const filters = [
        {
          id: 'filter_source_geofence',
          class: 'filter filter-source-geofence',
          modelName: 'sourceGeofence',
          placeholder: this.$t('sankeyChartTable.sourceGeofence'),
          searchable: false,
          options: orderBy(
            this.uniqueSourceGeofence.map(m => ({
              ...m,
              id: m.name + '',
              label: m.name,
              sortingLabel: m.name.toLowerCase(),
            })),
            'sortingLabel',
            'asc',
          ),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'SourceGeofence'),
          isMultiple: true,
        },
        {
          id: 'filter_target_geofence',
          class: 'filter filter-target-geofence',
          modelName: 'targetGeofence',
          placeholder: this.$t('sankeyChartTable.targetGeofence'),
          searchable: false,
          options: orderBy(
            this.uniqueTargetGeofence.map(m => ({
              ...m,
              id: m.name + '',
              label: m.name,
              sortingLabel: m?.name?.toLowerCase(),
            })),
            'sortingLabel',
            'asc',
          ),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'TargetGeofence'),
          isMultiple: true,
        },
        {
          id: 'filter_material_type',
          class: 'filter filter-material-type',
          modelName: 'materialType',
          placeholder: this.$t('sankeyChartTable.materialType'),
          searchable: false,
          options: orderBy(
            this.uniqueMaterialType.map(m => ({
              ...m,
              id: m.name + '',
              label: m.name,
              sortingLabel: m?.name?.toLowerCase(),
            })),
            'sortingLabel',
            'asc',
          ),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'MaterialType'),
          isMultiple: true,
        },
      ]
      return filters
    },
    massFlowFiltered() {
      if (this.filterOptionsSelected.length > 0) {
        return this.massFlow.reduce((massFlowFiltered, massFlow) => {
          const filterGroups = group(this.filterOptionsSelected, '__typename')
          const notInAllGroups = Object.keys(filterGroups).some(key => {
            const filterGroup = filterGroups[key]
            let massFlowPath
            let filterPath
            if (key === 'SourceGeofence') {
              massFlowPath = 'sourceGeofence'
              filterPath = 'id'
            } else if (key === 'TargetGeofence') {
              massFlowPath = 'targetGeofence'
              filterPath = 'id'
            } else if (key === 'MaterialType') {
              massFlowPath = 'materialType'
              filterPath = 'id'
            } else {
              throw new Error(`unhandled filter type ${key}`)
            }
            const found = filterGroup.find(filter => get(filter, filterPath) === get(massFlow, massFlowPath))
            return !found
          })
          if (!notInAllGroups) {
            massFlowFiltered.push(massFlow)
          }
          return massFlowFiltered
        }, [])
      }
      return this.massFlow
    },
    hasNoData() {
      return this.massFlow.length === 0
    },
    message() {
      const dimensionName = this.$t('massflow')
      return this.$t('messages.noDimensionData', { dimension: dimensionName })
    },
  },
  methods: {
    selectViewMode(mode) {
      this.viewMode = mode
    },
    setSelectedFilterOption(filter) {
      const found = this.filterOptionsSelected.find(f => f.id === filter.id)
      if (found) {
        this.filterOptionsSelected = this.filterOptionsSelected.filter(f => f.id !== filter.id)
      } else {
        this.filterOptionsSelected.push(filter)
      }
    },
    resetFilters() {
      this.filterOptionsSelected = []
    },
    textSearch(query) {
      this.searchQuery = query
    },
  },
  apollo: {
    massFlow: {
      query: MASSFLOW_QUERY,
      variables() {
        return {
          shifts: this.selectedTimeframeParam.shifts,
          from_ts: this.selectedTimeframeParam.start,
          till_ts: this.selectedTimeframeParam.end,
          assetIds: this.assetIds,
        }
      },
      skip() {
        return this.assetIds.length < 1 || !this.selectedTimeframeParam
      },
    },
  },
}
</script>
