var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FilterWrapperStyled', {
    attrs: {
      "elementSize": _vm.elementSize
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$tc('feed.filter.title')) + ":")]), _c('CircleBadge', {
    attrs: {
      "label": _vm.filterOptionsSelected.length,
      "size": "S"
    }
  }), _c('div', {
    staticClass: "filters"
  }, _vm._l(_vm.filtersAvailable, function (filter) {
    return _c('CustomMultiSelect', {
      key: filter.id,
      class: filter.class,
      attrs: {
        "track-by": "id",
        "openDirection": "bottom",
        "label": filter.label,
        "value": filter.value,
        "options": filter.options,
        "shouldNotResetSelectedOption": true,
        "disabled": filter.options.length === 0,
        "closeOnSelect": !filter.isMultiple,
        "searchable": filter.searchable,
        "multiple": filter.isMultiple,
        "placeholder": filter.placeholder,
        "modelName": filter.modelName,
        "maxHeight": 256,
        "optionsLimit": 6,
        "applyFilter": false,
        "internalSearch": true,
        "customTagSlot": true,
        "customNoOptionsSlot": true
      },
      on: {
        "select": function select($event) {
          return _vm.$emit('setSelectedFilterOption', $event);
        },
        "remove": function remove($event) {
          return _vm.$emit('setSelectedFilterOption', $event);
        }
      },
      scopedSlots: _vm._u([{
        key: "customLabelIcon",
        fn: function fn() {
          return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc(filter.modelName, 1)) + ":")])];
        },
        proxy: true
      }, {
        key: "tag",
        fn: function fn(option) {
          return [_c('SelectedTagStyled', [filter.value.length === 1 ? _c('strong', [_vm._v(" " + _vm._s(filter.value[0].label) + " ")]) : filter.value[0].id === option.option.id ? _c('strong', [_vm._v(_vm._s(filter.placeholder) + " " + _vm._s(filter.value.length))]) : _c('span')])];
        }
      }, {
        key: "noOptions",
        fn: function fn() {
          return [_c('strong', [_vm._v(_vm._s(filter.placeholder))])];
        },
        proxy: true
      }], null, true)
    });
  }), 1), _c('FilterActionStyled', [_c('button', {
    staticClass: "styleless",
    attrs: {
      "disabled": _vm.filterOptionsSelected.length === 0
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('resetFilters');
      }
    }
  }, [_vm._v(_vm._s(_vm.$tc('reset')))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }