export default function (s) {
  if (!s) {
    return 'null'
  }
  return s
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-')
}
