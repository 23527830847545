var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SankeyChartWidgetStyled', [!_vm.$apollo.loading && _vm.massFlowData.length === 0 ? _c('NoDataBlockStyled', [_vm._v(" " + _vm._s(_vm.$tc('messages.noDataForSelectedTime')) + " ")]) : _vm.massFlowData.length > 0 && _vm.sourcesSummed.length > 0 ? [_c('svg', {
    staticClass: "sankey",
    attrs: {
      "viewBox": "0 0 ".concat(_vm.totalWidth, " ").concat(_vm.totalHeight)
    }
  }, [_c('defs', _vm._l(_vm.materialTypes, function (materialType) {
    return _c('LinearGradientStyled', {
      key: materialType.id,
      attrs: {
        "id": materialType.id,
        "endColor": materialType.endColor,
        "x1": "0%",
        "y1": "0%",
        "x2": "100%",
        "y2": "0%"
      }
    }, [_c('stop', {
      attrs: {
        "offset": "0%",
        "id": "start"
      }
    }), _c('stop', {
      attrs: {
        "offset": "100%",
        "id": "end"
      }
    })], 1);
  }), 1), _c('g', {
    staticClass: "sources"
  }, _vm._l(_vm.sourcesSummed, function (source, i) {
    return _c('SourceStyled', {
      key: i,
      attrs: {
        "x": 0,
        "y": source.y,
        "width": _vm.sourceWidth,
        "height": source.size
      }
    });
  }), 1), _c('g', {
    staticClass: "targets"
  }, _vm._l(_vm.targetsSummed, function (target, i) {
    return _c('TargetStyled', {
      key: i,
      attrs: {
        "type": target.type,
        "targetColor": target.targetColor,
        "x": _vm.totalWidth - _vm.sourceWidth,
        "y": target.y,
        "width": _vm.sourceWidth,
        "height": target.size
      }
    });
  }), 1), true ? _c('g', {
    staticClass: "flows"
  }, _vm._l(_vm.flowsWithCoords, function (flow, i) {
    return _c('path', {
      key: "flow-".concat(i),
      staticClass: "flow",
      attrs: {
        "fill": "url(#grad_".concat(flow.type, ")"),
        "d": flow.d
      }
    });
  }), 0) : _vm._e(), _c('g', {
    staticClass: "names"
  }, [_vm._l(_vm.sourcesSummed, function (source, i) {
    return _c('SvgTextStyled', {
      key: 'text-source-' + i,
      attrs: {
        "x": _vm.sourceWidth * 1.5,
        "y": source.y + Math.round(source.size / 2),
        "font-size": "32px;",
        "alignment-baseline": "middle"
      }
    }, [_vm._v(" " + _vm._s(source.name) + " · " + _vm._s(source.massFormated) + " · " + _vm._s(source.cycles) + " " + _vm._s(_vm.$tc('sankeyChart.cycle', source.cycles)) + " ")]);
  }), _vm._l(_vm.targetsSummed, function (target, i) {
    return _c('SvgTextStyled', {
      key: 'text-target-' + i,
      attrs: {
        "x": _vm.totalWidth - _vm.sourceWidth * 1.5,
        "y": target.y + Math.round(target.size / 2),
        "font-size": "32px;",
        "alignment-baseline": "middle",
        "text-anchor": "end"
      }
    }, [_vm._v(" " + _vm._s(target.name) + " · " + _vm._s(target.massFormated) + " · " + _vm._s(target.cycles) + " " + _vm._s(_vm.$tc('sankeyChart.cycle', target.cycles)) + " ")]);
  })], 2)])] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }